import { Component, inject, input } from '@angular/core';
import { Store } from '@ngrx/store';

import { PrivaDialogButton } from '@priva/components/dialog-simple';
import { Step } from '@priva/components/stepped-dialog-simple';

import { BlockingDialog } from 'app/shared/dialogs';
import { AppActions, AppState } from 'app/state';

// TODO lvb, simple intro dialog. Can be removed after user testing

@Component({
    selector: 'app-intro',
    templateUrl: './intro.component.html',
    styleUrl: './intro.component.scss',
})
export class IntroComponent extends BlockingDialog {
    private readonly store = inject<Store<{ app: AppState }>>(Store);
    public titleKey = input<string>();

    public subTitleKey = input<string>();

    public textKey = input<string>();

    public textParams = input<object>();

    public activeStep = 0;
    public steps: Step[] = [
        {
            id: '1',
            title: 'Maak gebruik van "Device Templates"',
            completed: true,
        },
        {
            id: '2',
            title: 'Koppelen van apparaten',
            completed: true,
        },
    ];

    constructor() {
        super();
    }

    /* istanbul ignore next lvb, just dispatch single action */
    public close() {
        this.store.dispatch(AppActions.closeDialog({}));
    }

    /* istanbul ignore next lvb, add one */
    public handleButtonClick(button: PrivaDialogButton) {
        switch (button.id) {
            case 'next':
                this.activeStep = this.activeStep + 1;
                break;
            case 'previous':
                this.activeStep = this.activeStep - 1;
                break;
            case 'cancel':
            case 'done':
            default:
                this.close();
        }
    }
}
